<template>
  <div role="contentinfo" class="mb-5">
    <template v-clock v-if="salesReport.msg">
      <v-row dense>
        <v-col cols="auto">
          <span class="pts-label primary--text">
            {{ salesReport.msg }}
          </span>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="salesReport.input || salesReport.output">
      <template v-cloak v-if="salesReport.input">
        <h4 class="heading mb-2">В настоящее время действуют акции:</h4>
        <v-row dense>
          <v-col cols="auto">
            <span class="pts-label primary--text">
              {{ salesReport.input | toString }}
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-cloak v-if="salesReport.output">
        <h4 class="heading mb-2 mt-4">К вашему заказу применены:</h4>
        <v-row dense>
          <v-col cols="auto">
            <span class="pts-label primary--text">
              {{ salesReport.output | toString }}
            </span>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
/* eslint-disable no-unused-vars */
export default {
  filters: {
    toString(value, ...args) {
      if (value && Array.isArray(value)) return value?.join(', ')
      return null
    },
  },

  computed: {
    ...mapState('checkout', ['cart']),

    salesReport() {
      return {
        msg: this.cart?.msg,
        input: this.cart?.sales_control_input,
        output: this.cart?.sales_control_output,
      }
    },
  },
}
</script>
