<template>
  <v-container class="py-7">
    <cart-head />

    <cart-items :items="cartItems" />

    <cart-recommend v-if="cartItems.length" />

    <cart-gift />

    <template v-if="loaded && !cartIsEmpty">
      <v-text-field
        class="mb-4"
        label="Тип заказа"
        :value="deliveryTypeName"
        readonly
        outlined
        hide-details
        :disabled="$store.getters['app/isQrMenu']"
        @click.stop="dialog = true"
      >
        <template v-slot:append>
          <v-icon color="secondary">{{ icons.mdiDotsVertical }}</v-icon>
        </template>
      </v-text-field>

      <template v-if="canUsePromo">
        <v-row v-if="activePromo" class="mb-4" align="center">
          <v-col>
            Вы применили промокод
            <span class="primary--text font-weight-bold">{{
              activePromo
            }}</span>
          </v-col>
          <v-col cols="auto">
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="removePromo"
              small
              >Отменить</v-btn
            >
          </v-col>
        </v-row>

        <v-row v-else dense class="mb-5">
          <v-col>
            <v-text-field
              outlined
              label="Промокод"
              v-model="promo"
              hide-details
            />
          </v-col>
          <v-col cols="auto">
            <v-btn depressed color="primary" x-large @click="applyPromo"
              >Применить</v-btn
            >
          </v-col>
        </v-row>
      </template>

      <sales-report />

      <div class="mb-5" v-if="pointsAvailable && canUseBonuses && isAuthorized">
        <div class="heading mb-3">Бонусы</div>

        <template v-if="cart.pts_label_earn && cart.points_enabled">
          <p class="pts-label primary--text">{{ cart.pts_label_earn }}</p>
        </template>

        <template v-if="pointsApply">
          <v-row dense class="mb-4" align="center">
            <v-col class="bonuses-description">
              Вы использовали
              <span class="error--text">{{ pointsApply }}</span> бонусов для
              оплаты заказа
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="secondary"
                outlined
                small
                depressed
                @click="removeBonuses"
                >Отменить</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div class="bonuses-description mb-3">
            {{ $store.state.checkout.cart.available_points_label }}
          </div>
          <v-row dense class="mb-4">
            <v-col>
              <v-text-field
                outlined
                label="Кол-во баллов"
                v-model.number="bonuses"
                @keydown="validateSyncHandler"
                hide-details
                type="number"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn depressed color="primary" x-large @click="applyBonuses"
                >Применить</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </div>

      <v-row align="center" dense>
        <v-col>Стоимость товаров:</v-col>
        <v-col cols="auto">
          {{ cartSubtotalPrice }} {{ $store.getters['app/currency'] }}
        </v-col>
      </v-row>

      <v-row align="center" dense v-if="!useGeoAddressDelivery && !isPickup">
        <v-col>Стоимость доставки:</v-col>
        <v-col cols="auto">
          {{ deliveryPrice }} {{ $store.getters['app/currency'] }}
        </v-col>
      </v-row>

      <v-row align="center" dense v-if="cartDiscount">
        <v-col>Скидка:</v-col>
        <v-col cols="auto">
          - {{ cartDiscount }} {{ $store.getters['app/currency'] }}
        </v-col>
      </v-row>

      <!-- <v-row align="center" dense v-if="pointsApply">
        <v-col>Оплата бонусами:</v-col>
        <v-col cols="auto">
          - {{ pointsApply }} {{ $store.getters['app/currency'] }}
        </v-col>
      </v-row> -->

      <v-row align="center" dense v-if="deliveryPrice">
        <v-col>Доставка:</v-col>
        <v-col cols="auto">
          {{ deliveryPrice }} {{ $store.getters['app/currency'] }}
        </v-col>
      </v-row>

      <v-row class="heading mb-4" align="center" dense>
        <v-col>Итого:</v-col>
        <v-col cols="auto">
          {{ cartTotalPrice }}
          {{ $store.getters['app/currency'] }}
        </v-col>
      </v-row>
    </template>

    <div v-if="cartErrors && cartErrors.length" class="warning--text mb-4">
      <p v-for="(error, i) in cartErrors" :key="i">{{ error }}</p>
    </div>

    <v-dialog v-model="dialog" max-width="400" :persistent="!deliveryType">
      <v-card>
        <v-card-title class="headline justify-center text-center py-8"
          >Выберите тип заказа</v-card-title
        >
        <v-card-actions class="justify-center pa-0">
          <v-btn
            v-for="(type, i) in deliveryTypes"
            :key="i"
            :color="type.value == 'pickup' ? 'secondary' : 'primary'"
            class="order-type-button grow ma-0"
            tile
            depressed
            x-large
            @click="loadData(type.value)"
            >{{ type.text }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="cartIsEmpty && loaded" class="text-center">
      Ваша корзина пуста.
      <br />Добавьте что-нибудь из
      <router-link
        :to="{ name: 'Catalog' }"
        class="warning--text font-weight-black"
        >меню</router-link
      >
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mdiClose, mdiDotsVertical, mdiCheck } from '@mdi/js'
import CartHead from '@/components/CartHead'
import CartItems from '@/components/CartItems'
import CartGift from '@/components/CartGift'
import CartRecommend from '@/components/recommend/CartRecommend'
import actionButtonMixin from '@/mixins/actionButton'
import SalesReport from '@/components/SalesReport.vue'

// Provide only valid key codes for input stream.

const VALID_SYNC_KEY_CODES = [8, 9, 16, 17, 18, 37, 39]

const INPUT_VALID_SYNC_REGEX = /^[0-9]+$/gim

export default {
  name: 'Cart',

  mixins: [actionButtonMixin],

  components: {
    CartItems,
    CartHead,
    CartGift,
    CartRecommend,
    SalesReport,
  },

  data: () => ({
    icons: {
      mdiClose,
      mdiDotsVertical,
      mdiCheck,
    },
    loaded: false,
    dialog: false,
    promo: '',
    bonuses: '',
  }),

  computed: {
    actionButton() {
      return {
        active: true,
        action: () => {
          if (
            this.$store.getters['app/alternativeAuth'] &&
            !this.$store.getters['user/isAuthorized']
          ) {
            this.$router.push({
              name: 'Login',
              query: {
                to: 'Checkout',
              },
            })
          } else {
            this.$router.push({ name: 'Checkout' })
          }
        },
        text: 'Продолжить',
        disabled: this.cartDisabled,
      }
    },

    isPickup() {
      return this.deliveryType == 'pickup'
    },

    deliveryTypeName() {
      return this.deliveryTypes.find((item) => item.value == this.deliveryType)[
        'text'
      ]
    },

    cartDisabled() {
      return !this.cartTotalPrice
    },

    cartTotalMinus() {
      return this.$price.roundupPrice(
        this.cartTotalPrice - this.deliveryPrice - this.pointsApply
      )
    },

    cartErrors() {
      return this.cart?.cart_error
    },

    activePromo() {
      return this.promoDetails?.voucher_name
    },

    isAuthorized() {
      return this.$store.getters['user/isAuthorized']
    },

    ...mapGetters('app', [
      'useStreetAutocomplete',
      'useGeoAddressDelivery',
      'canUseBonuses',
      'canUsePromo',
    ]),

    ...mapGetters('checkout', [
      'cartItems',
      'cartSubtotalPrice',
      'cartIsEmpty',
      'cartTotalPrice',
      'cartDiscount',
      'deliveryPrice',
      'deliveryType',
      'promoDetails',
      'pointsAvailable',
      'pointsApply',
    ]),

    ...mapState('checkout', ['cart', 'deliveryTypes']),
  },

  methods: {
    validateSyncHandler(event) {
      const keyCode = event.keyCode
      const currentChar = String.fromCharCode(keyCode)
      if (
        !VALID_SYNC_KEY_CODES.includes(keyCode) &&
        !currentChar.match(INPUT_VALID_SYNC_REGEX)
      ) {
        event.preventDefault()
        event.stopImmediatePropagation()
      }
    },
    async loadData(deliveryType) {
      const { commit, dispatch } = this.$store
      this.dialog = false
      commit('overlay/update', { active: true })
      await dispatch('checkout/loadCart', deliveryType)
      commit('overlay/update', { active: false })
      this.loaded = true
    },

    async applyPromo() {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      const params = {
        voucher_name: this.promo,
        // transaction_type
      }
      const response = await dispatch('checkout/applyPromo', params)
      if (response.code != 1) {
        commit('snackbar/update', {
          active: true,
          text: response.msg,
        })
        commit('overlay/update', { active: false })
        return
      }
      await dispatch('checkout/loadCart')
      commit('overlay/update', { active: false })
    },

    async removePromo() {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      await dispatch('checkout/removePromo')
      await dispatch('checkout/loadCart')
      commit('overlay/update', { active: false })
    },

    async applyBonuses() {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      const response = await dispatch('checkout/applyBonuses', this.bonuses)
      if (response.code != 1) {
        commit('snackbar/update', { active: true, text: response.msg })
      } else {
        await dispatch('checkout/loadCart')
      }
      commit('overlay/update', { active: false })
    },

    async removeBonuses() {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      await dispatch('checkout/removeBonuses')
      await dispatch('checkout/loadCart')
      commit('overlay/update', { active: false })
    },
  },

  async created() {
    const deliveryType = this.$store.getters['checkout/deliveryType']
    await this.loadData(deliveryType || 'delivery')

    /* if (!this.cartIsEmpty) {
      this.dialog = true;
    } */

    this.cartIsEmpty
  },

  async mounted() {
    document.querySelector('title').innerText = 'Корзина'

    await this.$nextTick()
    const actionBtn = document.querySelector('.action-btn-wrapper')
    if (actionBtn) {
      actionBtn.classList.add('express-submit-cart')
    }
  },
}
</script>

<style lang="scss" scoped>
.bonuses-description {
  line-height: 1;
}

.v-application .headline {
  font-size: 20px !important;
}

.clear-button {
  text-decoration: underline;
}

.pts-label {
  // font-size: 14px;
  font-weight: 600;
}

.text-small {
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  max-width: 120px;
  line-height: 1;
  text-align: right;
}

::v-deep .v-dialog {
  margin: 8px;
}

.order-type-button {
  width: 0;
  font-size: 14px;
}
</style>
