<template>
  <div v-if="gift" class="mb-15">
    <div class="heading mb-7">Выбранный подарок</div>
    <v-card class="cart-gift" color="white" height="90">
      <div class="d-flex flex-no-wrap">
        <v-avatar size="90" tile>
          <v-img :src="gift.presents_vendor_image" />
        </v-avatar>

        <div class="cart-gift-body">
          <v-row align="start" dense class="flex-nowrap">
            <v-col>
              <div class="cart-gift-name dark--text mb-3">{{gift.presents_item_name}}</div>
              <div class="cart-gift-price primary--text mb-1">Бесплатно</div>
            </v-col>
            <v-col cols="auto">
              <v-btn icon color="secondary" class="ml-auto mt-n1 mb-1" small @click="remove()">
                <v-icon>{{icons.mdiClose}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  data: () => ({
    icons: {
      mdiClose,
    },
  }),

  computed: {
    gift() {
      return this.$store.state.checkout?.cart?.gift_cart?.gift_featured
        ?.gift_desc;
    },
  },

  methods: {
    async remove() {
      const { commit, dispatch } = this.$store;
      commit("overlay/update", { active: true });
      await dispatch("gift/removeGift", this.gift?.presents_item_id);
      commit("overlay/update", { active: false });
    },
  },
};
</script>

<style>
.cart-gift {
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
  line-height: 1;

  @media (max-width: 359.98px) {
    font-size: 14px;
  }
}

.cart-gift-body {
  width: 100%;
  padding: 10px;
}

.cart-gift-name {
  font-weight: 600;
}
</style>