<template>
  <div class="mb-8">
    <v-card
      class="cart-item mb-4"
      color="white"
      v-for="(item, i) in items"
      :key="i"
      height="90"
    >
      <div class="d-flex flex-no-wrap">
        <component
          :is="
            +item.total_price !== 0 || item?.sub_item?.length
              ? 'router-link'
              : 'div'
          "
          :to="getDetailPageLink(item, i)"
        >
          <v-avatar size="90" tile>
            <v-img :src="item.photo" />
          </v-avatar>
        </component>

        <div class="cart-item-body">
          <v-row align="start" dense class="flex-nowrap">
            <v-col>
              <div class="item-name dark--text mb-1">
                {{ item.item_name | truncate(30, '...') }}
              </div>
              <div class="item-size secondary--text">
                {{ item.size_words }}
                <span v-if="item.sub_item">(c добавками)</span>
              </div>
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="+item.total_price > 0"
                icon
                color="secondary"
                class="ml-auto mt-n1 mb-1"
                small
                @click="removeItem(i)"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" dense class="flex-nowrap">
            <v-col>
              <span v-if="item.discount" class="old-price error--text mr-1">{{
                getItemPrice(item, true)
              }}</span>
              <span class="font-weight-black">
                {{ getItemPrice(item) }}
                {{ $store.getters['app/currency'] }}
              </span>
            </v-col>
            <v-col cols="auto">
              <span class="qty">{{ item.qty }} шт.</span>

              <!-- <v-btn
                small
                color="primary"
                class="item-qty"
                :to="getDetailPageLink(item, i)"
              >{{item.qty}}</v-btn>-->
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  name: 'CartItems',

  props: ['items'],

  data: () => ({
    icons: {
      mdiClose,
    },
  }),

  methods: {
    getItemPrice(item, old = false) {
      let addonsPrice = 0
      if (item.sub_item) {
        item.sub_item.forEach((add) => {
          addonsPrice += add.addon_price * add.addon_qty
        })
      }
      return (
        (old ? item.normal_price : item.discounted_price) * item.qty +
        addonsPrice
      )
    },

    async removeItem(i) {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      await dispatch('checkout/removeItem', i)
      commit('overlay/update', { active: false })
    },

    getDetailPageLink(item, i) {
      return {
        name: 'Product',
        params: {
          section: item.category_id,
          product: item.item_id,
        },
        query: { row: i },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.qty {
  font-size: 14px;
  font-weight: 300;
}

.cart-item {
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
  line-height: 1;
  @media (max-width: 359.98px) {
    font-size: 14px;
  }
}

.item-size {
  font-size: 12px;
}

.cart-item-body {
  width: 100%;
  padding: 10px;
}

.item-name {
  font-weight: 600;
}

.old-price {
  text-decoration: line-through;
  font-size: 14px;
}

.v-btn.item-qty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 8px;
  font-weight: 400;
  padding: 0 4px;
  font-size: 14px;
  box-shadow: none;
}
</style>
